import { useEffect, useState } from "react";
import axios from "axios";
// import Dana from "../../components/dana/Dana";
import Details from "../../components/details/Details";
import Header from "../../components/header/Header";
// import Locations from "../../components/locations/Locations";
import Networks from "../../components/networks/Networks";
// import Topics from "../../components/topics/Topics";
import "./home.css";
import Timeline from "../../components/timeline/Timeline";
import CloudChart from "../../components/cloudChart/CloudChart";

function Home() {
  const [typeAgenda, setTypeAgenda] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [agendas, setAgendas] = useState([]);
  const [detailObject, setDetailObject] = useState(null);
  const [query, setQuery] = useState("");
  const [tanggal, setTanggal] = useState("");

  // console.log(query, "query", tanggal, "tanggal");
  // console.log(agendas, "agendas");
  // GET AGENDA
  useEffect(() => {
    setIsLoading(true);

    const fetchDataAgenda = async () => {
      switch (typeAgenda) {
        case "all":
          let allAgenda = [];
          if (query || tanggal) {
            allAgenda = await axios.post(
              "https://apictarsa.tms.my.id/api/agendafilter/all",
              {
                query: query,
                tahun: tanggal,
              }
            );
          } else {
            allAgenda = await axios.get(
              "https://apictarsa.tms.my.id/api/agenda/all"
            );
          }
          setAgendas(allAgenda.data.nodes);
          setIsLoading(false);
          break;
        case "covid":
          let covid = [];
          if (query || tanggal) {
            covid = await axios.post(
              "https://apictarsa.tms.my.id/api/agendafilter/1",
              {
                query: query,
                tahun: tanggal,
              }
            );
          } else {
            covid = await axios.get("https://apictarsa.tms.my.id/api/agenda/1");
          }
          setAgendas(covid.data.nodes);
          setIsLoading(false);
          break;
        case "sekolah":
          let sekolah = [];
          if (query || tanggal) {
            sekolah = await axios.post(
              "https://apictarsa.tms.my.id/api/agendafilter/2",
              {
                query: query,
                tahun: tanggal,
              }
            );
          } else {
            sekolah = await axios.get(
              "https://apictarsa.tms.my.id/api/agenda/2"
            );
          }
          setAgendas(sekolah.data.nodes);
          setIsLoading(false);
          break;
        case "komunitas":
          let komunitas = [];
          if (query || tanggal) {
            komunitas = await axios.post(
              "https://apictarsa.tms.my.id/api/agendafilter/3",
              {
                query: query,
                tahun: tanggal,
              }
            );
          } else {
            komunitas = await axios.get(
              "https://apictarsa.tms.my.id/api/agenda/3"
            );
          }
          setAgendas(komunitas.data.nodes);
          setIsLoading(false);
          break;
        case "bencana":
          let bencana = [];
          if (query || tanggal) {
            bencana = await axios.post(
              "https://apictarsa.tms.my.id/api/agendafilter/4",
              {
                query: query,
                tahun: tanggal,
              }
            );
          } else {
            bencana = await axios.get(
              "https://apictarsa.tms.my.id/api/agenda/4"
            );
          }
          setAgendas(bencana.data.nodes);
          setIsLoading(false);
          break;
        case "unit":
          let unit = [];
          if (query || tanggal) {
            unit = await axios.post(
              "https://apictarsa.tms.my.id/api/agendafilter/5",
              {
                query: query,
                tahun: tanggal,
              }
            );
          } else {
            unit = await axios.get("https://apictarsa.tms.my.id/api/agenda/5");
          }
          setAgendas(unit.data.nodes);
          setIsLoading(false);
          break;
        case "gebrag":
          let gebrag = [];
          if (query || tanggal) {
            gebrag = await axios.post(
              "https://apictarsa.tms.my.id/api/agendafilter/6",
              {
                query: query,
                tahun: tanggal,
              }
            );
          } else {
            gebrag = await axios.get(
              "https://apictarsa.tms.my.id/api/agenda/6"
            );
          }
          setAgendas(gebrag.data.nodes);
          setIsLoading(false);
          break;
        case "mitra":
          let mitra = [];
          if (query || tanggal) {
            mitra = await axios.post(
              "https://apictarsa.tms.my.id/api/agendafilter/7",
              {
                query: query,
                tahun: tanggal,
              }
            );
          } else {
            mitra = await axios.get("https://apictarsa.tms.my.id/api/agenda/7");
          }
          setAgendas(mitra.data.nodes);
          setIsLoading(false);
          break;

        default:
          let all = [];
          if (query || tanggal) {
            all = await axios.post(
              "https://apictarsa.tms.my.id/api/agendafilter/all",
              {
                query: query,
                tahun: tanggal,
              }
            );
          } else {
            all = await axios.get("https://apictarsa.tms.my.id/api/agenda/all");
          }
          setAgendas(all.data.nodes);
          setIsLoading(false);
          break;
      }
    };

    fetchDataAgenda();
  }, [typeAgenda, query, tanggal]);

  // SET QUERY WORLD CLOUD

  const [queryCalon, setQueryCalon] = useState("indonesia");

  return (
    <div className="home">
      <Header setQuery={setQuery} setTanggal={setTanggal} />
      <div className="main-content">
        <Details
          agendas={agendas}
          typeAgenda={typeAgenda}
          setTypeAgenda={setTypeAgenda}
          isLoading={isLoading}
          setDetailObject={setDetailObject}
          detailObject={detailObject}
          setQuery={setQuery}
          setTanggal={setTanggal}
          query={queryCalon}
        />
        <Networks
          detailObject={detailObject}
          agendas={agendas}
          query={queryCalon}
        />
        <div className="right-content">
          {/* <Dana detailObject={detailObject} />
          <Locations
            detailObject={detailObject}
            agendas={agendas}
            isLoading={isLoading}
          />
          <Topics /> */}
          {/* WORD CLOUD */}
          <CloudChart query={queryCalon} media={"news"} />
        </div>
      </div>
      <Timeline setQueryCalon={setQueryCalon} />
    </div>
  );
}

export default Home;

// import TimeLineChart from "../timeLineChart/TimeLineChart";
import { useRef } from "react";
import "./timeline.css";

function Timeline({ setQueryCalon }) {
  const calonRef = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    setQueryCalon(calonRef.current.value);
  };

  return (
    <div className="timeline">
      {/* <div className="timeline-header">
        <div className="timeline-header-desc">
          <h2>Timeline</h2>
          <span className="section-header">
            <span>news articles across</span>
            <span className="days">59 days</span>
            <span className="hours">10 hrs</span>
            <span className="minuts">10 mnt</span>
            <span className="seconds">0 sec up to the current date</span>
            <span className="current-date">9/12/2021</span>
          </span>
        </div>
        <span className="linkUrl">
          <a href="http://localhost:3000">CTArsa</a>
        </span>
      </div>
      <div className="timeline-svg">
        <TimeLineChart />
      </div> */}

      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Cari query" ref={calonRef} />
        <button type="submit">Cari</button>
      </form>
    </div>
  );
}

export default Timeline;

import { useEffect, useRef } from "react";
import "./cloudChart.css";
import { select, json } from "d3";
import * as cloud from "d3-cloud";

const width = 200;
const height = 250;

function CloudChart({ query, media }) {
  // const query = "jokowi";
  const svgRef = useRef();

  useEffect(() => {
    // Sudah ada element g belum?
    if (svgRef.current.children[0]) {
      svgRef.current.children[0].remove();
    }

    const svg = select(svgRef.current).attr("viewBox", [0, 0, width, height]);

    json("https://app01.tmsdev.space/api/wordcloud", {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ query: query, media_sosial: media }),
    }).then(function (data) {
      const designLayout = cloud();

      const svgLayout = designLayout
        .size([width, height])
        .words(
          data.map(function (d) {
            return { text: d.word, size: d.size };
          })
        )
        .padding(14)
        .rotate(0)
        .fontSize(function (d) {
          return d.size;
        })
        .on("end", (words) => {
          // console.log(words, "words");
          svg
            .append("g")
            .attr(
              "transform",
              "translate(" +
                svgLayout.size()[0] / 2 +
                "," +
                svgLayout.size()[1] / 2 +
                ")"
            )
            .selectAll("text")
            .data(words)
            .enter()
            .append("text")
            .style("font-size", function (d) {
              return d.size;
            })
            .style("fill", "#69b3a2")
            .attr("text-anchor", "middle")
            .style("font-family", "Impact")
            .attr("transform", function (d) {
              return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
            })
            .text(function (d) {
              return d.text;
            });
        });
      svgLayout.start();
    });
  }, [query, media]);

  return (
    <div className="cloudChart" style={{ height: "100%" }}>
      <svg ref={svgRef} style={{ height: "100%" }}></svg>
    </div>
  );
}

export default CloudChart;
